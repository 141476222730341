<template>
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.13987 0.58374C4.41161 0.58374 0.607666 4.42323 0.607666 9.11594C0.607666 13.8442 4.41161 17.6481 9.13987 17.6481C13.8326 17.6481 17.6721 13.8442 17.6721 9.11594C17.6721 4.42323 13.8326 0.58374 9.13987 0.58374ZM11.9839 10.8224C12.3394 11.1423 12.3394 11.6756 11.9839 11.9956C11.664 12.3511 11.1307 12.3511 10.8108 11.9956L9.13987 10.3247L7.43343 11.9956C7.11347 12.3511 6.58021 12.3511 6.26025 11.9956C5.90474 11.6756 5.90474 11.1423 6.26025 10.8224L7.93114 9.11594L6.26025 7.44505C5.90474 7.12509 5.90474 6.59183 6.26025 6.27187C6.58021 5.91637 7.11347 5.91637 7.43343 6.27187L9.13987 7.94276L10.8108 6.27187C11.1307 5.91637 11.664 5.91637 11.9839 6.27187C12.3394 6.59183 12.3394 7.12509 11.9839 7.44505L10.313 9.11594L11.9839 10.8224Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCancelFilled'
}
</script>
